const refsCommon = {
	// //modals

	// //sections

	// forms
	form: document.getElementById("form"),

	// tags
	domains: document.querySelectorAll("#domain"),
};

export default refsCommon;
